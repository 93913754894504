<!--岗位管理-->
<template>
    <div class="post-container">
        <div class="table-search">
            <div class="search-L">
                <el-input v-model="tableParams.roleName" placeholder="请输入岗位名称搜索" @input="kwChange" clearable
                    class="mr15" />
            </div>
            <div class="search-R">
                <el-button type="primary" @click="onAdd()">添加岗位</el-button>
            </div>
        </div>
        <Table :tableData="tableData" :column="postColumns" border :headerCellStyle="headerCellStyle">
            <template slot-scope="scope" slot="operation">
                <el-button type="text" size="medium" @click.stop="onEdit(scope.row)"
                    v-has="'merchant_role_edit'">编辑</el-button>
                <el-button type="text" size="medium" @click.stop="onDel(scope.row)"
                    v-has="'merchant_role_del'">删除</el-button>
                <el-button type="text" size="medium" @click.stop="onAuto(scope.row)"
                    v-has="'merchant_role_menu'">菜单权限</el-button>
            </template>
        </Table>
        <div class="table-footer">
            <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
                :page-size="tableParams.size" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
                :current-page.sync="currentPage" @current-change="onCurrentPage">
            </el-pagination>
        </div>
        <!-- 编辑弹框 -->
        <el-dialog :visible.sync="editShow" :title="editTitle" :close-on-click-modal="false" width="30%" center>
            <el-form :model="params"  ref="form-ref">
                <el-form-item label="所属商户" prop="merchantIds" label-width="120px">
                    <el-select v-model="params.merchantIds" placeholder="请选择所属商户" clearable multiple
                        :disabled="params.roleId != ''">
                        <el-option v-for="item in merchantList" :key="item.merchantId" :label="item.merchantName"
                            :value="item.merchantId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="岗位名称" prop="roleName" label-width="120px" :rules="[{ required: true, message: '请输入岗位名称', trigger: 'blur' }]">
                    <el-input v-model.trim="params.roleName" autocomplete="off" placeholder="请输入岗位名称"
                        maxlength="20"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              
                <el-button type="primary" @click="onSubmit('form-ref')">确定</el-button>
            </div>
        </el-dialog>
        <!-- 授权弹框 -->
        <el-dialog :visible.sync="autoShow" :title="autoTitle" :close-on-click-modal="false" width="40%">
            <section class="tree-box">
                <div class="tree-item">
                    <h1 class="tree-title">门店管理系统：</h1>
                    <p class="check-all">
                        <el-checkbox :indeterminate="pcCheckItem" v-model="pcCheckAll"
                            @change="onCheckPcAll">全选</el-checkbox>
                    </p>
                    <el-tree :data="menuListPc" ref="treePc" :check-strictly="checkStrictly" show-checkbox
                        node-key="funId" :default-expand-alls="true" :highlight-current="true"
                        :check-on-click-node="true" :props="defaultProps" @check-change="onCheckPcItem">
                    </el-tree>
                </div>
                <div class="tree-item">
                    <h1 class="tree-title">壹启智享商户端：</h1>
                    <p class="check-all">
                        <el-checkbox :indeterminate="appCheckItem" v-model="appCheckAll"
                            @change="onCheckAppAll">全选</el-checkbox>
                    </p>
                    <el-tree :data="menuListApp" ref="treeApp" :check-strictly="checkStrictly" show-checkbox
                        node-key="funId" :default-expand-alls="true" :highlight-current="true"
                        :check-on-click-node="true" :props="defaultProps" @check-change="onCheckAppItem">
                    </el-tree>
                </div>
            </section>
            <div slot="footer" class="dialog-footer">
                <el-button @click="autoShow = false">取 消</el-button>
                <el-button type="primary" @click="onAutoSubmit()">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { postColumns } from "@/db/tableColumns";
import {

    getRoleMenu,
    getRoleAuto
} from "@/api/setting/role"
import {
    getRoleList, getMerchantList, getRoleEdit,
    getRoleDel,
} from "@/api/merchant/shopRole"
import {
    getMenuBySpId,
} from "@/api/setting/menu";

export default {
    components: {

    },

    data() {
        return {
            postColumns,
            headerCellStyle: {
                background: '#FAFAFA',
                color: '#000000',
            },
            // 列表
            currentPage: 1, // 当前页
            total: 0, // 总条数  
            tableData: [],
            tableParams: {
                page: 1,
                size: 10,
                roleName: "",
            },
            // 表单
            editShow: false, //弹框开关
            editTitle: "添加岗位", //弹框姓名
            params: {
                roleId: "", //岗位ID
                roleName: "", //岗位名称
                merchantIds: [],//所属商户
            },
            // 授权
            autoRoleId: "",
            autoTitle: "菜单授权",
            autoShow: false,
            menuListPc: [],
            menuListApp: [],
            defaultProps: {
                label: 'functionName',
                children: 'functionVos',
            },
            checkStrictly: true,
            // 全选
            pcCheckAll: false,
            pcCheckItem: false,
            appCheckAll: false,
            appCheckItem: false,
            merchantList: [],
        };
    },
    created() {
    },
    mounted() {
        this.getRoleList();
        this.getMenuBySpId();
    },
    methods: {
        getMerchantList(type) {
            getMerchantList().then(res => {
                this.merchantList = res.data;
                if (type == 1) {
                    this.merchantList.unshift({
                        merchantId: 0,
                        merchantName: "全部商户",
                    })
                }

            })
        },
        // 【请求】岗位列表
        getRoleList() {
            let data = this.tableParams;
            getRoleList(data).then((res) => {
                this.tableData = res.data.list;
                this.total = res.data.total;
            });
        },

        // 【请求】新增岗位
        getRoleEdit() {
            let data = this.params;
            if (data.merchantIds[0] == 0) {
                data.merchantIds = [];
            }
            getRoleEdit(data).then(res => {
                if (res.isSuccess == "yes") {
                    this.editShow = false;
                    if (data.roleId) {
                        this.getRoleList();
                    } else {
                        this.reload()
                    }
                }
            })
        },

        // 【请求】全部菜单列表
        getMenuBySpId() {
            let data = {
                merchantId: this.params.merchantId, //岗位名称
            };
            getMenuBySpId(data).then(res => {
                this.menuListPc = res.data.pc;
                this.menuListApp = res.data.app;
            })
        },

        // 【监听】表格重载
        reload() {
            this.tableParams.page = 1;
            this.currentPage = 1;
            this.getRoleList();
        },
        // 【监听】表格模糊查询
        kwChange: _.debounce(function () {
            this.reload();
        }, 500),
        // 【监听】表格分页点击
        onCurrentPage(page) {
            this.tableParams.page = page;
            this.getRoleList();
        },

        // 【监听】表格条数点击
        onSizeChange(size) {
            this.tableParams.size = size;
            this.reload();
        },

        // 【监听】新增用户
        onAdd() {
            this.getMerchantList();
            this.editShow = true;
            this.editTitle = "添加岗位";
            this.params = {
                roleId: '', //用户ID
                roleName: "", //岗位名称
                merchantIds: [], //所属商户
            }
            this.$nextTick(() => {
                this.$refs["form-ref"].clearValidate();
            });
        },

        // 【监听】编辑按钮
        onEdit(row) {
            this.getMerchantList(1);
            this.editShow = true;
            this.editTitle = "编辑岗位";
            this.params = JSON.parse(JSON.stringify(row));
            if (this.params.merchantIds==null||this.params.merchantIds.length==0) {
                this.params.merchantIds = [0];
            }
            this.$nextTick(() => {
                this.$refs["form-ref"].clearValidate();
            });
        },

        // 【监听删除】
        onDel(row) {
            let data = {
                roleId: row.roleId
            }
            this.$confirm('是否确认删除该岗位?', '删除岗位', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error'
            }).then(() => {
                getRoleDel(data).then(res => {
                    if (res.isSuccess == "yes") {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.getRoleList();
                    }
                })
            });
        },

        // 【监听】表单提交
        onSubmit:_.debounce(function(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.getRoleEdit();
                } else {
                    return false;
                }
            });
        },500),
     

        // 【监听】菜单授权回显
        onAuto(row) {
            this.autoShow = true;
            this.autoRoleId = row.roleId
            this.autoTitle = ` ${row.roleName}`
            let data = {
                roleId: row.roleId,
            }
            getRoleMenu(data).then(res => {
                this.$nextTick(() => {
                    this.$refs.treePc.setCheckedNodes(res.data.roleFunctions);
                    this.$refs.treeApp.setCheckedNodes(res.data.roleFunctions);
                    this.onCheckPcItem();
                    this.onCheckAppItem();
                })
            })
        },

        // 【监听】菜单授权保存
        onAutoSubmit() {
            let pc = this.$refs.treePc.getCheckedNodes(false, true);
            let app = this.$refs.treeApp.getCheckedNodes(false, true);
            let data = {
                roleId: this.autoRoleId,
                roleFunctions: [...pc, ...app]
            }
            getRoleAuto(data).then(res => {
                if (res.isSuccess == "yes") {
                    this.$message({
                        type: 'success',
                        message: res.message
                    });
                    this.autoShow = false;
                }
            })
        },

        // 【转换】pc端菜单项
        changeMenu(list) {
            let arr = JSON.parse(JSON.stringify(list));
            for (let i of arr) {
                if (i.functionVos) {
                    arr = [...arr, ...i.functionVos];
                    for (let j of i.functionVos) {
                        if (j.functionVos) {
                            arr = [...arr, ...j.functionVos];
                        }
                    }
                }
            }
            return arr
        },

        // 【监听】pc全选
        onCheckPcAll(flag) {
            flag ? this.$refs.treePc.setCheckedNodes(this.changeMenu(this.menuListPc)) : this.$refs.treePc.setCheckedNodes([])
            this.pcCheckItem = false;
        },

        // 【监听】pc单选
        onCheckPcItem() {
            let pc = this.$refs.treePc.getCheckedNodes(false, true);
            let pcAll = this.changeMenu(this.menuListPc);
            this.pcCheckAll = pc.length == pcAll.length;
            this.pcCheckItem = pc.length > 0 && pc.length < pcAll.length;
        },

        // 【监听】小程序全选
        onCheckAppAll(flag) {
            flag ? this.$refs.treeApp.setCheckedNodes(this.changeMenu(this.menuListApp)) : this.$refs.treeApp.setCheckedNodes([])
            this.appCheckItem = false;
        },

        // 【监听】小程序单选
        onCheckAppItem() {
            let app = this.$refs.treeApp.getCheckedNodes(false, true);
            let appAll = this.changeMenu(this.menuListApp);
            this.appCheckAll = app.length == appAll.length;
            this.appCheckItem = app.length > 0 && app.length < appAll.length;
        },


    },
};
</script>
<style lang="scss" scoped>
.post-container {
    padding: 0.15rem;
    box-sizing: border-box;

    .table-search {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.2rem;

        .search-L {
            display: flex;
        }
    }

    .table-footer {
        margin-top: 0.1rem;
        display: flex;
        justify-content: flex-end;
    }

    .tree-box {
        display: flex;
        justify-content: space-around;
    }

    .tree-title {
        min-width: 2rem;
        padding: .1rem .2rem;
        height: .28rem;
        background: #eee;
        font-size: .16rem;
    }

    .tree-item {
        border: 1px solid #eee;
    }

    .el-tree {
        padding: .1rem;
    }

    .check-all {
        padding: .1rem .1rem 0 .1rem;
    }
}
</style>